export const commonEnvironment = {
  //accounts and authns
  archContext: {
    authn: `/authns`,
    activity: `/activities`,
    role: `/roles`,
    permission: `/permissions`,
    banner: `/banner`
  },
  //echarge
  plugContext: {
    plug: `/plug`
  },
  locationContext: {
    location: `/location`,
    transactions: `/transactions`,
    connection: `/connection`,
    park: `/location/park`,
    zone: `/location/zone`,
    echarger: `/location/echarger`,
    charge: `/location/charge`,
    vendor: `/location/vendor`,
    site: `/location/site`,
    firmware: `/location/firmware`
  },
  //accounts
  accountContext: {
    account: `/account`,
    customer: `/account/customer`,
    device: `/account/device`,
    paymentprovider: `/account/paymentprovider`,
    admin: `/account/admin`,
    car: `/account/car`,
    user: `/account/user`
  },
  //order
  orderContext: {
    order: `/order`,
    pos: `/order/pos`
  },
  googleMapsApiKey: `AIzaSyBDQcb3_Y64rOZfgbgKhXnT00VFVL9Kub8`
}
