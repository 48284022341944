import { MenuItem } from '../../shared/models/generic/menuItem.interface'

export const menuItems: MenuItem[] = [
  { label: `DASHBOARD`, icon: `fa-chart-simple`, route: `/dashboard/home` },
  {
    label: `GLOBAL_CHARGING_HISTORY`,
    icon: `fa-shopping-cart`,
    route: `/charging-history/orders`
  },
  {
    label: `OPERATOR_CHARGING_HISTORY`,
    icon: `fa-credit-card`,
    route: `/charging-history/orders`
  },
  {
    label: `ACCOUNTS`,
    icon: `fa-users`,
    subMenu: [
      { label: `DRIVERS`, route: `/accounts/drivers` },
      { label: `PAYMENT_PROVIDERS`, route: `/accounts/payment-providers` },
      {
        label: `CHARGE_POINTS_OPERATORS`,
        route: `/accounts/charge_points-operators`
      },
      { label: `HARDWARE_SUPPLIERS`, route: `/accounts/hardware-suppliers` },
      { label: `DEVICES`, route: `/accounts/devices` }
    ]
  },
  { label: `DRIVERS_PRIVILIGES`, icon: `fa-user-shield`, route: `` },
  {
    label: `SECURITY`,
    icon: `fa-lock`,
    subMenu: [
      { label: `ACTIVITIES`, route: `/security/activities` },
      { label: `PERMISSIONS`, route: `/security/permissions` },
      { label: `ROLES`, route: `/security/roles` },
      { label: `PRIVILEGES`, route: `/security/privileges` }
    ]
  },
  {
    label: `LOCATIONS`,
    icon: `fa-charging-station`,
    subMenu: [
      { label: `CHARGE_POINTS`, route: `/locations/charge-points` },
      { label: `SITES`, route: `/locations/sites` },
      { label: `FIRMWARES`, route: `/locations/firmwares` }
    ]
  },
  {
    label: `UTILITY_CONFIGURATION`,
    icon: `fa-plug`,
    subMenu: [
      { label: `CARS`, route: `/utility-configuration/cars` },
      { label: `PLUGS`, route: `/utility-configuration/plugs` }
    ]
  },
  {
    label: `SYSTEM`,
    icon: `fa-cogs`,
    subMenu: [
      { label: `SYSTEM_MONITORING`, route: `/system/system-monitoring` },
      { label: `APP_BANNER`, route: `/system/app-banner` }
    ]
  }
]
