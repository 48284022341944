import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms'
import { ChargingProcess } from 'src/app/shared/models/locations/charging-process.class'

export function isAuthorizedAction (
  permissions: string[],
  action: string
): boolean {
  return permissions.includes(action)
}

export function formatDateToISO (date: string | null): string {
  if (!date) {
    return ``
  }

  //Ensure that the parts array has at least year, month, and day
  if (date.split(`-`).length < 3) {
    throw new Error(`Invalid date format.`)
  }

  const isoDateString = new Date(date).toISOString()

  return isoDateString
}

export const noWhitespacesOnly = (control: AbstractControl) =>
  `${control.value || ``}`.trim().length ? null : { required: true }

export const namePattern =
  /^[-'a-zA-Z \u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+$/

export const splitAndCleanValidator = (
  control: AbstractControl
): { [key: string]: any } | null => {
  if (typeof control.value === `string`) {
    const arrayOfStrings = control.value
      .split(`\n`)
      .map((item) => item.trim())
      .filter((item) => item.length > 0)
    control.setValue(arrayOfStrings, { emitEvent: false }) //Prevent infinite loop
  }
  return null //No validation errors
}

export enum UnitOfMeasure {
  W = `W`,
  KW = `kW`,
  WH = `Wh`,
  KWH = `kWh`
}

export const normalizeUnits = (value, unit) => {
  switch (unit) {
    case UnitOfMeasure.WH:
      //Wh -> kWh
      //Used Accuracy: One decimal points
      //simplification of Math.round((value / 1000) * 10) / 10
      return Math.round(value / 100) / 10
    case UnitOfMeasure.W:
      //W -> kW
      return Math.round(value / 100) / 10
    default:
      return value
  }
}

//helper enum for charger details
export enum CommandEloaded {
  GUN_STAT = `GUN_STAT`,
  CHARGE_INFO = `CHARGE_INFO`,
  CHARGE_START = `CHARGE_START`,
  CHARGE_STOP = `CHARGE_STOP`,
  CHARGE_ALLOW = `CHARGE_ALLOW`,
  CHARGE_DENY = `CHARGE_DENY`
}

export enum CommandOCPP {
  REMOTE_START_TRANSACTION = `RemoteStartTransaction`,
  REMOTE_STOP_TRANSACTION = `RemoteStopTransaction`,
  RESET = `Reset`,
  TRIGGER_MESSAGE = `TriggerMessage`,
  UNLOCK_CONNECTOR = `UnlockConnector`
}

export enum MessageTriggerOCPP {
  BOOT_NOTIFICATION = `BootNotification`,
  HEARTBEAT = `Heartbeat`,
  STATUS_NOTIFICATION = `StatusNotification`,
  METER_VALUES = `MeterValues`
}

export const isEmptyObject = (obj): boolean => {
  for (const key in obj) {
    if (obj[key] !== undefined
      && obj[key] !== null
      && obj[key]?.length !== 0) {
      return false
    }
  }

  return true
}

export const getAveragePower = (charge: ChargingProcess): number => {
  const { energy , startDate, stopDate } = charge
  if (!startDate || energy === undefined) {
    return
  }
  //Calculate the time charging
  const start = new Date(startDate)
  const stop = stopDate ? new Date(stopDate) : new Date()

  const timeChargingInMs = stop.getTime() - start.getTime()
  const timeChargingInH = timeChargingInMs / 1000 / 60 / 60
  //Calculate average power
  return Math.round(energy / timeChargingInH * 10) / 10
}

export const calculateKW = (current: number, voltage: number): number =>
  Math.round(current * voltage / 100) / 10

export const openingTimesValidator =
(control: AbstractControl): ValidationErrors | null => {
  const { twentyfourseven, regularHours } = control.value
  if (twentyfourseven) {
    return null
  }
  //todo: double check the validation here
  if (regularHours.filter(({ weekday }) => weekday !== undefined).length) {
    return null
  }
  return { regularHours: true }
}

export const timeOrderValidator =
 (control: AbstractControl): ValidationErrors | null => {
   const periodBegin = control.get(`periodBegin`)?.value
   const periodEnd = control.get(`periodEnd`)?.value

   if (periodBegin && periodEnd && periodBegin >= periodEnd) {
     return { timeOrderInvalid: true } //Period end is earlier than period begin
   }

   return null //No error
 }

//Custom validator for time zone
export const timeZoneValidator
= (timeZones: string[]): ValidatorFn => (control: AbstractControl)
: { [key: string]: any } | null => timeZones.includes(control.value)
  ? null
  : { invalidTimeZone: { value: control.value } }

export const isIFrame =
(input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === `IFRAME`

//Custom Validator for energySources FormArray
export const percentageSumValidator =
(control: AbstractControl): ValidationErrors | null => {
  if (control instanceof FormArray) {
    //Only apply the validation if the array is not empty
    if (control.controls.length === 0) {
      return null //Allow empty array
    }

    const sum = control.controls.reduce((total, group) => {
      const percentageControl = group.get(`percentage`)
      return total + (percentageControl ? percentageControl.value : 0)
    }, 0)

    return sum === 100 ? null : { sumNotEqualTo100: true }
  }
  return null
}

//Custom validator for plugs FormArray
export const atLeastOneInstalledPlug =
(control: AbstractControl): ValidationErrors | null => {
  const plugs = control.value as { number: number }[]
  const hasInstalledPlug = plugs.some(plug => plug.number === 1)

  return hasInstalledPlug ? null : { noInstalledPlug: true }
}
