import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from '../../login/services/auth.service'
import { User } from '../../shared/models/users/user.class'
import { filter } from 'rxjs'
import { isAuthorizedAction } from 'src/assets/helpers/helper'
@Component({
  selector: `eld-navbar`,
  templateUrl: `./navbar.component.html`,
  styleUrls: [`./navbar.component.scss`]
})
export class NavbarComponent {
  public loggedInUser: User

  protected isAuthorized = false

  constructor (
    private router: Router,
    public authService: AuthService,
    public translateService: TranslateService
  ) {
    this.authService.loggedInUser.pipe(filter(u => !!u))
      .subscribe(user => {
        this.loggedInUser = user
        this.translateService.use(user.locale)
      })
    this.translateService.addLangs([`en`, `de`, `it`, `fr`])
    this.translateService.setDefaultLang(`en`)

    //permission check
    this.authService.permissions$.forEach(p => {
      this.isAuthorized = isAuthorizedAction(p,`PROFILE`)
    })

  }

  public get currentLang (): string {
    return (
      this.translateService.currentLang ?? this.translateService.defaultLang
    )
  }

  public setTranslateLocale (locale:string):void {
    this.translateService.use(locale)
  }

  public logout () {
    this.authService.logout()
    this.router.navigateByUrl(`/login`)
  }
}
