import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, interval, Observable, of, ReplaySubject, Subscription, throwError } from 'rxjs'
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators'
import { Response } from 'src/app/shared/models/http/response.class'
import { ILoginInfo } from 'src/app/shared/models/auth/loginInfo.interface'
import { User } from 'src/app/shared/models/users/user.class'
import { StorageService } from 'src/app/shared/services/storage.service'
import { environment } from 'src/environments/environment'
import { Authn } from 'src/app/shared/models/security/authn.class'

@Injectable({
  providedIn: `root`
})
export class AuthService {
  public loggedIn = new BehaviorSubject<boolean>(false)

  public loggedInUser = new BehaviorSubject<User>(undefined)

  //ReplaySubject(1): ensures that subscribers always get the last emitted value,
  //avoiding the premature empty array issue.
  private permissionsSubject = new ReplaySubject<string[]>(1)

  public permissions$ = this.permissionsSubject.asObservable().pipe(
    distinctUntilChanged()
  )

  private refreshIntervalSubscription: Subscription | null = null

  constructor (
    private http: HttpClient,
    private storageService: StorageService,
    private translateService: TranslateService
  ) {
    const token = storageService.getToken()
    if (token) {
      this.loggedIn.next(true)
      this.initializePermissions()
      this.startPermissionRefresh()
    }
  }

  public initializePermissions (): void {
    this.fetchPermissions().subscribe({
      next: (permissions) => this.permissionsSubject.next(permissions),
      error: () => this.permissionsSubject.next([])
    })
  }

  private fetchPermissions (): Observable<string[]> {
    return this.getMe().pipe(
      map((user) => user?.authn?.roles ?? []),
      map((roles) =>
        roles.reduce(
          (acc, { permissions }) => [
            ...acc,
            ...permissions
              .filter(
                ({ active, app }) =>
                  active && (app === `app-dashboard` || app === `app-public`)
              )
              .map(({ label }) => label)
          ],
          []
        )
      ),
      //Return empty permissions on error
      catchError(() => of([]))
    )
  }

  public refreshPermissions (): void {
    this.fetchPermissions().subscribe((permissions) =>
      this.permissionsSubject.next(permissions)
    )
  }

  public get isLoggedIn$ (): Observable<boolean> {
    return this.loggedIn.asObservable()
  }

  private validResponse (observable: Observable<any>) {
    return observable.pipe(
      tap(({ message }) => {
        if (message !== `OK`) {
          throw new Error(message)
        }
      }),
      map((response) => response.data),
      catchError((error) => {
        throw new Error(error.error?.message || error.message)
      })
    )
  }

  public login (loginInfo: ILoginInfo): Observable<string> {
    return this.validResponse(
      this.http.put<Response<any>>(
        `${environment.baseUrl}${environment.archContext.authn}`,
        loginInfo
      )
    ).pipe(
      tap((token) => {
        this.storageService.setToken(token)
        this.loggedIn.next(true)
        this.refreshPermissions()
        //Start periodic refresh after login
        this.startPermissionRefresh()
      })
    )
  }

  public getMe (): Observable<User> {
    return this.validResponse(
      this.http.get<Response<any>>(`${environment.baseUrl}/account/user`)
    ).pipe(
      tap((user) => {
        this.translateService.use(user.locale)
        this.loggedInUser.next(user)
      })
    )
  }

  public updatePassword (oldPassword: string, newPassword: string)
  : Observable<any> {
    return this.http.patch<any>(`${environment.baseUrl}${environment.archContext.authn}/password`, {
      oldPassword,
      newPassword
    })
  }

  public resetPassword (email: string, token: string, newPassword: string)
  : Observable<any> {
    return this.http
      .post<any>(`${environment.baseUrl}${environment.archContext.authn}/password`, {
        email,
        token,
        newPassword
      })
      .pipe(
        tap((response) => {
          const msg: string = response.message
          //forced to do this because of inconsistent back-end errors
          if (!msg.startsWith(`ERROR`) && msg !== `OK`) {
            throw new Error(`ERROR.${msg}`)
          }
        })
      )
  }

  public requestChangePwdCode (email: string): Observable<any> {
    return this.http
      .get<any>(`${environment.baseUrl}${environment.archContext.authn}/password/${email}`)
      .pipe(
        tap((response) => {
          const msg: string = response.message
          if (msg === `WRONG_EMAIL`) {
            throw new Error(`ERROR.WRONG_EMAIL_ADDRESS`)
          }
          if (!msg.startsWith(`ERROR`) && msg !== `OK`) {
            throw new Error(`ERROR.${msg}`)
          }
        })
      )
  }

  public getAuthn (authnId: string): Observable<Response<Authn | null>> {
    return this.http
      .get<Response<Authn | null>>(`${environment.baseUrl}/authn/${authnId}`)
      .pipe(
        switchMap(({ message, data }) =>
          message === `OK`
            ? of({ message, data })
            : throwError(() => new Error(message))
        ),
        map(({ message, data }) =>
          data ? new Response<Authn>(message, new Authn(data))
            : new Response<null>(message, null)
        )
      )
  }

  public logout (): void {
    this.storageService.deleteToken()
    this.loggedInUser.next(undefined)
    this.loggedIn.next(false)
    //Clear cached permissions
    this.permissionsSubject.next([])
    //Stop periodic refresh
    this.stopPermissionRefresh()
  }

  private startPermissionRefresh (): void {
    if (!this.refreshIntervalSubscription) {
      //15 minutes interval
      this.refreshIntervalSubscription = interval(15 * 60 * 1000)
        .subscribe(() => this.refreshPermissions())
    }
  }

  private stopPermissionRefresh (): void {
    if (this.refreshIntervalSubscription) {
      this.refreshIntervalSubscription.unsubscribe()
      this.refreshIntervalSubscription = null
    }
  }
}
